<template>
	<v-container fluid>
		<v-container v-if="references.length == 0">{{ $t("reference.loading_all") }}</v-container>
		<v-container v-else>
			<h1 class="centrovane">{{ $t("reference.title")}}</h1>
			<v-row>
				<v-col>
					<v-select v-model="e6" :items="ref_fields" :menu-props="{ maxHeight: '400' }" :label="$t('reference.filter_field')" multiple @change="reference_display_status()"></v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-select v-model="e7" :items="ref_product_types" :menu-props="{ maxHeight: '400' }" :label="$t('reference.filter_product_type')" multiple @change="reference_display_status()"></v-select>
				</v-col>
			</v-row> 
			<v-row dense>
				<v-col class="d-flex flex-column" v-for="reference in references_to_display" :key="reference['id']" cols="12" sm="6" md="4" lg="3">
					<v-card class="flex d-flex flex-column" outlined tile elevation="24" route :to="create_route(reference['id'])">
						<v-row dense class="incenter seda">
							<v-col>
								<v-card-title class="headline">{{ reference['ref_name'] }}</v-card-title>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col class="incenter">
								<v-avatar class="profile" size="120" tile>
									<v-img contain :src="get_img_url(reference['link'])"></v-img>
								</v-avatar>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="centrovane">
								<v-card-subtitle>{{ reference["ref_project_name"] }}</v-card-subtitle>
							</v-col>
						</v-row>
						
						
						<!-- </div> -->
					</v-card>
				</v-col>
				<!-- <v-col class="d-flex flex-column" v-for="reference in references" :key="reference['id']" cols="12" sm="6"	md="4" lg="3">
					<v-card v-if="reference_display_status(reference)" class="flex d-flex flex-column" outlined tile elevation="24">
						<div class="d-flex flex-no-wrap justify-space-between">
							<div class="centrovane">
								<v-card-title>{{ reference['ref_name']}}</v-card-title>
								<v-card-subtitle class="textik">{{ reference["ref_project_name"] }}</v-card-subtitle>
								<v-img class="imager" contain :aspect-ratio="16 / 9" :src="get_img_url(reference['link'])"></v-img>
							</div>
						</div>
					</v-card>
				</v-col> -->
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import ApiService from "@/services/api_service";

export default {
	data: () => ({
		e6: [],
		e7: [],
		references: [],
		references_to_display: [],
		ref_fields: [],
		ref_product_types: []
	}),
	methods: {
		get_img_url(img) {
			return '/pictures/pics/reference/' + img
		},
		create_route(id) {
			return '/reference/' + id
		},
		reference_display_status() {
			this.references_to_display = []
			for(var i = 0; i < this.references.length; i++) {
				let show_reference = true
				if(this.e6.length > 0) {
					if(!this.e6.includes(this.references[i]['ref_field'])) show_reference = false
				}	
				if(this.e7.length > 0) {
					if(!this.e7.includes(this.references[i]['ref_product_type'])) show_reference = false
				}
				if(show_reference) this.references_to_display.push(this.references[i])
			}
			
		}
	},
	computed: {
	},
	mounted() {
		ApiService.get("/references").then((response) => {
			this.references = response.data
			this.references_to_display = response.data
			for(var i = 0; i < this.references.length; i++) {
				if(!this.ref_fields.includes(this.references[i]['ref_field'])) this.ref_fields.push(this.references[i]['ref_field'])
				if(!this.ref_product_types.includes(this.references[i]['ref_product_type'])) this.ref_product_types.push(this.references[i]['ref_product_type'])
			}
			this.ref_fields.sort()
			this.ref_product_types.sort()
		}).catch(() => {});
	},
};
</script>

<style scoped>
.imager {
	max-height: 100px;
	padding-top: 10px;
}
.textik {
	text-align: justify;
}
.centrovane {
	text-align: center;
}
.incenter {
	text-align: center;
}
.v-card__title {
	padding-top: 0px;
}
.headline {
	font-size: 1.2rem !important
}
.col {
	padding-top: 0px;
}
.incenter {
	text-align: center;
}
.headline {
	font-size: 1.2rem !important;
	display: block;
	padding: 0;
}
.seda {
	background-color: rgb(245, 245, 245);
	margin: 0;
}
</style>
